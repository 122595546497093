import api from "@/lib/Api"
import { ChatBatchMessage } from "@/models/crm/chatBatchMessage"
import { useAuthStore } from "@/store/core/auth"
import { pa } from "element-plus/es/locale"
interface State {
    pendingForm: any
    isFromRouteUserfinder: boolean
}

const batchMessage = {
    state: {
        pendingForm: null,
        isFromRouteUserfinder: false
    } as State,
    mutations: {
        updateBatchMessagePendingForm(state: State, values: any) {
            state.pendingForm = { ...values }
        },
        updateIsFromRouteUserfinder(state: State, val: boolean) {
            state.isFromRouteUserfinder = val
        }
    },
    actions: {
        getShareConfig({}) {
            return new Promise((resolve, reject) => {
                api.get("/admn/share_configs/")
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        getChatBatchMessages() {
            return new Promise((resolve, reject) => {
                api.get("/chat_batch_messages/")
                    .then(chatBatchMessages => {
                        resolve(chatBatchMessages)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createChatBatchMessage({}, params: ChatBatchMessage) {
            const authStore = useAuthStore()
            params.teamspace = authStore.teamspace
            return new Promise((resolve, reject) => {
                api.post("/chat_batch_messages/", params)
                    .then(chatBatchMessage => {
                        resolve(chatBatchMessage)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        updateChatBatchMessage({}, batchToEdit: ChatBatchMessage): Promise<ChatBatchMessage> {
            const authStore = useAuthStore()
            batchToEdit.teamspace = authStore.teamspace
            return new Promise((resolve, reject) => {
                api.patch(`/chat_batch_messages/${batchToEdit.id}/`, batchToEdit)
                    .then((editedBatch: ChatBatchMessage) => {
                        resolve(editedBatch)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteChatBatchMessage({}, chatBatchMessageId: any): Promise<void> {
            return new Promise((resolve, reject) => {
                api.delete(`/chat_batch_messages/${chatBatchMessageId}/`)
                    .then(() => {
                        resolve()
                    })
                    .catch((err: any) => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    },
    getters: {
        getBatchMessagePendingForm: (state: State) => state.pendingForm,
        getIsFromRouteUserfinder: (state: State) => state.isFromRouteUserfinder
    }
}

export default batchMessage
