import {
    RouteLocationNormalized,
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteRecordRaw
} from "vue-router"
import { RecentPage } from "@/models/various/home"
import { PATHS_WITHOUT_AUTH_REGEX } from "@/constants/core"

const Homepage = () => import("@/views/Homepage.vue")
import globalRoutes from "@/router/globalRoutes"
import companiesAndTerritoriesRoutes from "@/router/companiesAndTerritories"

/* Login */
import login from "@/router/loginRoutes"

/* User */
import user from "@/router/user"

/* CRM */
import crmTickets from "@/router/crm/crmTickets"
import batchMessage from "@/router/crm/batchMessage"
import batchNotif from "@/router/crm/batchNotif"
import callInterface from "@/router/crm/callInterface"
import userFinder from "@/router/crm/crmUserFinder"
import flashInfos from "@/router/crm/flashInfo"
import remoteConfig from "@/router/crm/remoteConfig"
import transitPassValidation from "@/router/crm/transitPassValidation"
import driverLicenseValidation from "@/router/crm/driverLicenseValidation"
import challenge from "@/router/crm/challenge"
import CeeAudit from "@/router/crm/ceeAudit"

/* Other */
import shareConfig from "@/router/other/shareConfig"
import userMap from "@/router/other/user_map"
import karosEvent from "@/router/other/karosEvent"
import adminUser from "@/router/other/adminUser"
import carpoolPlace from "@/router/other/carpoolPlace"
import kpiDeployment from "@/router/other/kpiDeployment"
import otpCodes from "@/router/other/otpCodes"
import appReleaseEula from "@/router/other/appReleaseEula"

/* Match */
import match from "@/router/match/match"

/* Various */
import userChatroom from "@/router/various/userChatroom"
import pricingAreas from "@/router/various/pricingAreas"
import { useHeadStore } from "@/store/core/head"
import { useIndexStore } from "@/store/core/index"

export const routes: RouteRecordRaw[] = [
    {
        path: "/",
        redirect: "/admin/"
    },
    {
        path: "/admin/",
        component: Homepage,
        name: "homePage",
        beforeEnter: () => {
            const store = useHeadStore()
            store.setTitle("")
        }
    },
    ...globalRoutes,
    ...companiesAndTerritoriesRoutes,
    ...user,
    ...callInterface,
    ...userMap,
    ...flashInfos,
    ...crmTickets,
    ...batchMessage,
    ...userFinder,
    ...batchNotif,
    ...shareConfig,
    ...karosEvent,
    ...adminUser,
    ...remoteConfig,
    ...transitPassValidation,
    ...driverLicenseValidation,
    ...challenge,
    ...userChatroom,
    ...match,
    ...pricingAreas,
    ...CeeAudit,
    ...carpoolPlace,
    ...kpiDeployment,
    ...appReleaseEula,
    ...otpCodes,
    ...login,
    {
        path: "/:pathMatch(.*)*",
        redirect: "/admin/"
    }
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const headStore = useHeadStore()
    const indexStore = useIndexStore()
    let title = to.meta.label as string | null | undefined
    let description = to.meta.description as string | null | undefined
    title && headStore.setTitle(title)
    indexStore.pageDescription = description ?? ""

    updateRecentPagesForHomepage(title, to)
    if (!PATHS_WITHOUT_AUTH_REGEX.test(to.fullPath) && !localStorage.getItem(import.meta.env.VITE_AUTH_TOKEN_LABEL)) {
        window.location.href = "/admin/login"
    } else {
        next()
    }
})
const updateRecentPagesForHomepage = (name: string | null | undefined, to: RouteLocationNormalized): void => {
    if (name && to.name) {
        const recentPagesStr = localStorage.getItem("recentPages")
        let recentPages: RecentPage[] = recentPagesStr ? JSON.parse(recentPagesStr) : []
        recentPages = recentPages.filter(recentPage => recentPage.label !== name)
        recentPages.unshift({
            label: name,
            routeName: to.name as string,
            path: to.path
        })
        recentPages = recentPages.slice(0, 4)
        localStorage.setItem("recentPages", JSON.stringify(recentPages))
    }
}

router.onError((error: Error, to: RouteLocationNormalized) => {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
        window.location.href = to.fullPath // Trigger a Hard Reload on import error.
    }
})
