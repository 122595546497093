import Api from "@/lib/Api"
import { downloadExcelFile } from "@/lib/utils"
import {
    TransitPassUserDeactivationPayload,
    TransitPassUserDeactivationResponse
} from "@/models/companiesAndTerritories/transitPass"

export default {
    actions: {
        syncTransitPasses() {
            return new Promise((resolve, reject) => {
                Api.get("/admn/transit_passes/")
                    .then(transitPasses => {
                        resolve(transitPasses)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        syncTerritoryTransitPasses({}, transitPassId: any) {
            return new Promise((resolve, reject) => {
                const endpoint = `/admn/transit_pass_partners/?transit_pass_id=${transitPassId}`
                Api.get(endpoint)
                    .then(transitPasses => {
                        resolve(transitPasses)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createTransitPass({}, transitPassToCreate: any) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/transit_passes/", transitPassToCreate)
                    .then(createdTransitPass => {
                        resolve(createdTransitPass)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateTransitPass({}, { uuid, transitPass }: any) {
            return new Promise((resolve, reject) => {
                Api.patch(`/admn/transit_passes/${uuid}/`, transitPass)
                    .then(updatedTransitPass => {
                        resolve(updatedTransitPass)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        createTransitPassPartners({}, payload: any) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/transit_pass_partners/", payload)
                    .then(transitPassPartners => {
                        resolve(transitPassPartners)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteTransitPassPartners({}, transitPassId: any): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.delete(`/admn/transit_pass_partners/${transitPassId}/`)
                    .then(() => {
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        },

        uploadTransitPassPicture({}, { payload, headers }: any): Promise<void> {
            const url = `/transit_pass_network_pictures/?transit_pass_uuid=${payload.uuid}&name=${payload.kind}`
            return new Promise((resolve, reject) => {
                Api.put(url, payload.data, { headers })
                    .then(() => {
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        },

        downloadTransitPassReport({}, transitPassUuid: string): Promise<void> {
            return new Promise((resolve, reject) => {
                const endpoint = `/admn/transit_passes/${transitPassUuid}/user_export/`
                Api.getFile(endpoint, { responseType: "blob" })
                    .then(response => {
                        downloadExcelFile(response)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        deactivateTransitPassUsers(
            {},
            { transitPassUuid, userUuids }: { transitPassUuid: string; userUuids: string[] }
        ): Promise<TransitPassUserDeactivationResponse> {
            return new Promise((resolve, reject) => {
                const endpoint = `/admn/transit_passes/${transitPassUuid}/user_deactivation/`
                const payload: TransitPassUserDeactivationPayload = {
                    user_uuids: userUuids
                }
                Api.post(endpoint, payload)
                    .then((response: TransitPassUserDeactivationResponse) => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}
