import { MobilityService } from "@/models/companiesAndTerritories/partners"
import Api from "@/lib/Api"

interface State {
    mobilityServices: MobilityService[]
}
export default {
    state: {
        mobilityServices: []
    } as State,
    mutations: {
        syncMobilityService(state: State, mobilityServices: MobilityService[]) {
            state.mobilityServices = mobilityServices
        },
        addOrUpdateMobilityService(state: State, mobilityService: MobilityService) {
            let mobilityServiceIndex = state.mobilityServices.findIndex(ms => ms.id === mobilityService.id)
            if (mobilityServiceIndex == -1) state.mobilityServices.push(mobilityService)
            else state.mobilityServices[mobilityServiceIndex] = mobilityService
        },
        deleteMobilityService(state: State, mobilityServiceId: number) {
            state.mobilityServices = state.mobilityServices.filter(ms => ms.id !== mobilityServiceId)
        },
        clearMobilityService(state: State) {
            state.mobilityServices = []
        }
    },
    actions: {
        getPartnerMobilityServices({ commit }: { commit: Function }, partnerId: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/mobility_services/?partner_id=${partnerId}`)
                    .then((mobilityServices: MobilityService[]) => {
                        commit("syncMobilityService", mobilityServices)
                        resolve(mobilityServices)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createMobilityService({ commit }: { commit: Function }, mobilityService: MobilityService) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/mobility_services/", mobilityService)
                    .then(createdMobilityService => {
                        commit("addOrUpdateMobilityService", createdMobilityService)
                        resolve(createdMobilityService)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateMobilityService({ commit }: { commit: Function }, mobilityService: MobilityService) {
            return new Promise((resolve, reject) => {
                Api.patch(`/admn/mobility_services/${mobilityService.id}/`, mobilityService)
                    .then(updatedMobilityService => {
                        commit("addOrUpdateMobilityService", updatedMobilityService)
                        resolve(updatedMobilityService)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteMobilityService({ commit }: { commit: Function }, mobilityServiceId: number): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.delete(`/admn/mobility_services/${mobilityServiceId}/`)
                    .then(() => {
                        commit("deleteMobilityService", mobilityServiceId)
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        }
    },
    getters: {
        getMobilityServices: (state: State) => state.mobilityServices,
        getMobilityServiceCount: (state: State) => state.mobilityServices.length
    }
}
